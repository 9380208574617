<template>
    <div>
        <Loader v-if="optionsLoading" />
        <form
            class="form"
            novalidate="novalidate"
            id="st_modal_application_type_form"
        >
            <div class="row pb-6">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>
                            {{ fields.name.label }} *
                        </label>
                        <st-input-text
                            :ref="fields.name.name"
                            :name="fields.name.name"
                            v-model="model[fields.name.name]"
                        />
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.description.label }}
                        </label>
                        <b-form-textarea
                            :ref="fields.description.name"
                            :name="fields.description.name"
                            v-model="model[fields.description.name]"
                        ></b-form-textarea>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.status.label }} *
                        </label>
                        <b-form-select
                            v-model="model[fields.status.name]"
                            :options="fields.status.options"
                            :ref="fields.status.name"
                            :name="fields.status.name">
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER")}}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <div class="form-group">
                        <b-form-checkbox
                            id="is_tax_cateogry_conditional"
                            v-model="isTaxCategoryConditional"
                            name="is_tax_cateogry_conditional"
                        >
                            {{ $t("APPLICATION_TYPE.PLACEHOLDERS.IS_TAX_CATEGORY_CONDITIONAL") }}
                        </b-form-checkbox>
                    </div>

                    <div v-show="isTaxCategoryConditional">
                        <div class="form-group">
                            <label>
                                {{ fields.construction_tax_category_id.label }} *
                            </label>
                            <b-form-select
                                v-model="model[fields.construction_tax_category_id.name]"
                                :options="fields.construction_tax_category_id.options"
                                :ref="fields.construction_tax_category_id.name"
                                :name="fields.construction_tax_category_id.name"
                            >
                                <template #first>
                                    <b-form-select-option
                                        :value="null"
                                        disabled
                                    >
                                        {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                    </b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.land_tax_category_id.label }} *
                            </label>
                            <b-form-select
                                v-model="model[fields.land_tax_category_id.name]"
                                :options="fields.land_tax_category_id.options"
                                :ref="fields.land_tax_category_id.name"
                                :name="fields.land_tax_category_id.name"
                            >
                                <template #first>
                                    <b-form-select-option
                                        :value="null"
                                        disabled
                                    >
                                        {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                    </b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.extra_tax_category_id.label }}
                            </label>
                            <b-form-select
                                v-model="model[fields.extra_tax_category_id.name]"
                                :options="fields.extra_tax_category_id.options"
                                :ref="fields.extra_tax_category_id.name"
                                :name="fields.extra_tax_category_id.name"
                            >
                                <template #first>
                                    <b-form-select-option
                                        :value="null"
                                    >
                                        {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                    </b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                    </div>

                    <div v-show="!isTaxCategoryConditional">
                        <div class="form-group">
                            <label>
                                {{ fields.tax_category_id.label }} *
                            </label>
                            <b-form-select
                                v-model="model[fields.tax_category_id.name]"
                                :options="fields.tax_category_id.options"
                                :ref="fields.tax_category_id.name"
                                :name="fields.tax_category_id.name"
                            >
                                <template #first>
                                    <b-form-select-option
                                        :value="null"
                                        disabled
                                    >
                                        {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                    </b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                        <div class="form-group">
                            <b-form-checkbox
                                v-model="model[fields.is_tax_fixed.name]"
                                :ref="fields.is_tax_fixed.name"
                                :name="fields.is_tax_fixed.name"
                            >
                                {{ $t("APPLICATION_TYPE.PLACEHOLDERS.IS_TAX_FIXED") }}
                            </b-form-checkbox>
                        </div>
                        <b-form-row>
                            <b-col>
                                <div class="form-group">
                                    <b-form-checkbox
                                        id="apply_discount"
                                        v-model="applyDiscount"
                                        name="apply_discount"
                                    >
                                        {{ $t("APPLICATION_TYPE.PLACEHOLDERS.APPLY_DISCOUNT") }}
                                    </b-form-checkbox>
                                </div>
                            </b-col>
                            <b-col v-show="applyDiscount">
                                <div class="form-group">
                                    <st-input-text
                                        :ref="fields.rural_percentage_discount.name"
                                        :name="fields.rural_percentage_discount.name"
                                        v-model="model[fields.rural_percentage_discount.name]"
                                    />
                                </div>
                            </b-col>
                        </b-form-row>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.discounts.label }}
                            <i class="fas fa-info-circle text-primary ml-2"  v-b-popover.hover.blur="popoverConfig"></i>
                        </label>
                        <b-form-select
                            v-model="model[fields.discounts.name]"
                            :options="discountsOptions"
                            :ref="fields.discounts.name"
                            :name="fields.discounts.name"
                            multiple
                            :select-size="16"
                        >
                            <template #first>
                                <b-form-select-option value="">{{$t("APPLICATION_TYPE.PLACEHOLDERS.NO_DISCOUNTS")}}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.uats.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.uats.name]"
                            :options="uatsOptions"
                            :ref="fields.uats.name"
                            :name="fields.uats.name"
                            multiple
                            :select-size="12"
                        >
                            <template #first>
                                <b-form-select-option value="">{{$t("APPLICATION_TYPE.PLACEHOLDERS.NO_UATS")}}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <div class="form-group">
                        <label>
                            {{ fields.tenant_id.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.tenant_id.name]"
                            :options="fields.tenant_id.options"
                            :ref="fields.tenant_id.name"
                            :name="fields.tenant_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.departments.label }}
                        </label>
                        <st-autocomplete
                            v-model="model[fields.departments.name]"
                            :options="departmentOptions"
                            :ref="fields.departments.name"
                            :name="fields.departments.name"
                            :fields="fields"
                            :model="model"
                            :formValidation="fv"
                            :disabled="!model[fields.tenant_id.name]"
                            @change="displayFunctions"
                        />
                    </div>

                    <div class="form-group">
                        <label>
                            {{ fields.functions.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.functions.name]"
                            :options="fields.functions.options"
                            :ref="fields.functions.name"
                            :name="fields.functions.name"
                            :disabled="!model[fields.departments.name]"
                            multiple
                            :select-size="fields.functions.options.length + 1">
                            <template #first>
                                <b-form-select-option value="">{{$t("GENERAL.SELECT_PLACEHOLDER")}}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>
                            {{ fields.related_application_type_ids.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.related_application_type_ids.name]"
                            :options="filterRelatedAppOptions"
                            :ref="fields.related_application_type_ids.name"
                            :name="fields.related_application_type_ids.name"
                            multiple
                            :select-size="3"
                            @change="checkReference">
                            <template #first>
                                <b-form-select-option value="">{{$t("APPLICATION_TYPE.PLACEHOLDERS.NO_REF_DOC")}}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.form_citizen_id.label }} *
                        </label>
                        <st-select
                            v-model="model[fields.form_citizen_id.name]"
                            :ref="fields.form_citizen_id.name"
                            :name="fields.form_citizen_id.name"
                            :formValidation="fv"
                            :field="fields.form_citizen_id"
                            searchable
                            placeholderOption
                        >
                        </st-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.form_staff_id.label }} *
                        </label>
                        <st-select
                            v-model="model[fields.form_staff_id.name]"
                            :ref="fields.form_staff_id.name"
                            :name="fields.form_staff_id.name"
                            :formValidation="fv"
                            :field="fields.form_staff_id"
                            searchable
                            placeholderOption
                        >
                        </st-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.reject_form_staff_id.label }} *
                        </label>
                        <st-select
                            v-model="model[fields.reject_form_staff_id.name]"
                            :ref="fields.reject_form_staff_id.name"
                            :name="fields.reject_form_staff_id.name"
                            :formValidation="fv"
                            :field="fields.reject_form_staff_id"
                            searchable
                            placeholderOption
                        >
                        </st-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.archive_document_type_code.label }} *
                        </label>
                        <b-form-select
                            v-model="model[fields.archive_document_type_code.name]"
                            :options="arhiveDocumentTypeList"
                            :ref="fields.archive_document_type_code.name"
                            :name="fields.archive_document_type_code.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.application_document_series_type_id.label }} *
                        </label>
                        <st-select
                            v-model="model[fields.application_document_series_type_id.name]"
                            :ref="fields.application_document_series_type_id.name"
                            :name="fields.application_document_series_type_id.name"
                            :field="fields.application_document_series_type_id"
                            :formValidation="fv"
                            searchable
                            placeholderOption
                        >
                        </st-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.document_series_type_id.label }} *
                        </label>
                        <st-select
                            v-model="model[fields.document_series_type_id.name]"
                            :ref="fields.document_series_type_id.name"
                            :name="fields.document_series_type_id.name"
                            :field="fields.document_series_type_id"
                            :formValidation="fv"
                            searchable
                            placeholderOption
                        >
                        </st-select>
                    </div>
                    <div v-if="editMode" class="form-group">
                        <label>
                            {{ fields.application_document_template_id.label }}
                        </label>
                        <b-form-select
                            v-model=" model[fields.application_document_template_id.name]"
                            :options="fields.application_document_template_id.options"
                            :ref="fields.application_document_template_id.name"
                            :name="fields.application_document_template_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <div v-if="editMode" class="form-group">
                        <label>
                            {{ fields.document_template_id.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.document_template_id.name]"
                            :options="fields.document_template_id.options"
                            :ref="fields.document_template_id.name"
                            :name="fields.document_template_id.name"

                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <div v-if="editMode" class="form-group">
                        <label>
                            {{ fields.reject_document_template_id.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.reject_document_template_id.name]"
                            :options="fields.reject_document_template_id.options"
                            :ref="fields.reject_document_template_id.name"
                            :name="fields.reject_document_template_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <div v-if="editMode" class="form-group">
                        <label>
                            {{ fields.review_document_template_id.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.review_document_template_id.name]"
                            :options="fields.review_document_template_id.options"
                            :ref="fields.review_document_template_id.name"
                            :name="fields.review_document_template_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <div v-if="editMode" class="form-group">
                        <label>
                            {{ fields.public_document_template_id.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.public_document_template_id.name]"
                            :options="fields.public_document_template_id.options"
                            :ref="fields.public_document_template_id.name"
                            :name="fields.public_document_template_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <div v-if="editMode && !model[fields.is_internal.name]" class="form-group">
                        <label>
                            {{ fields.review_application_type_id.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.review_application_type_id.name]"
                            :options="reviewApplicationTypeId"
                            :ref="fields.review_application_type_id.name"
                            :name="fields.review_application_type_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <div v-if="editMode && !model[fields.is_internal.name]" class="form-group">
                        <label>
                            {{ fields.internal_application_type_id.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.internal_application_type_id.name]"
                            :options="reviewApplicationTypeId"
                            :ref="fields.internal_application_type_id.name"
                            :name="fields.internal_application_type_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.workflow_id.label }} *
                        </label>
                        <st-select
                            v-model="model[fields.workflow_id.name]"
                            :ref="fields.workflow_id.name"
                            :name="fields.workflow_id.name"
                            :formValidation="fv"
                            :field="fields.workflow_id"
                            searchable
                            placeholderOption
                        >
                        </st-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.remaining_period.label }} *
                        </label>
                        <st-input-text
                            :ref="fields.remaining_period.name"
                            :name="fields.remaining_period.name"
                            v-model="model[fields.remaining_period.name]"
                            type="number"
                        />
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.order.label }}
                        </label>
                        <st-input-text
                            :ref="fields.order.name"
                            :name="fields.order.name"
                            v-model="model[fields.order.name]"
                            type="number"
                        />
                    </div>


                    <div class="form-group">
                        <b-form-checkbox
                            v-model="model[fields.is_auto.name]"
                            :ref="fields.is_auto.name"
                            :name="fields.is_auto.name"
                            @change="checkReference"
                        >
                            {{ fields.is_auto.label }}
                        </b-form-checkbox>
                    </div>

                    <div class="form-group">
                        <b-form-checkbox
                            v-model="model[fields.is_internal.name]"
                            :ref="fields.is_internal.name"
                            :name="fields.is_internal.name"
                            @change="removeValidations"
                        >
                            {{ fields.is_internal.label }}
                        </b-form-checkbox>
                    </div>

                    <div class="form-group">
                        <b-form-checkbox
                            v-model="model[fields.has_digital_seal.name]"
                            :ref="fields.has_digital_seal.name"
                            :name="fields.has_digital_seal.name"
                            @change="removeValidations"
                        >
                            {{ fields.has_digital_seal.label }}
                        </b-form-checkbox>
                    </div>

                    <label class="application-type-category">{{ $t("APPLICATION_TYPE.FORM.USAGE_CATEGORY") }}</label>

                    <div class="form-group">
                        <b-form-checkbox
                          v-model="model[fields.is_inbound_category.name]"
                          :ref="fields.is_inbound_category.name"
                          :name="fields.is_inbound_category.name"
                        >
                            {{ fields.is_inbound_category.label }}
                        </b-form-checkbox>
                    </div>

                    <div class="form-group">
                        <b-form-checkbox
                        v-model="model[fields.is_internal_category.name]"
                        :ref="fields.is_internal_category.name"
                        :name="fields.is_internal_category.name"
                        >
                        {{ fields.is_internal_category.label }}
                        </b-form-checkbox>
                    </div>

                    <div class="form-group">
                    <b-form-checkbox
                        v-model="model[fields.is_outbound_category.name]"
                        :ref="fields.is_outbound_category.name"
                        :name="fields.is_outbound_category.name"
                    >
                        {{ fields.is_outbound_category.label }}
                    </b-form-checkbox>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { ApplicationTypeModel } from "@/modules/application-type/application-type-model";
import Message from '@/shared/message/message';

const { fields } = ApplicationTypeModel;

const formSchema = new FormSchema([
    fields.id,
    fields.name,
    fields.description,
    fields.status,
    fields.form_citizen_id,
    fields.form_staff_id,
    fields.reject_form_staff_id,
    fields.document_template_id,
    fields.reject_document_template_id,
    fields.review_document_template_id,
    fields.public_document_template_id,
    fields.application_document_template_id,
    fields.document_series_type_id,
    fields.application_document_series_type_id,
    fields.tax_category_id,
    fields.construction_tax_category_id,
    fields.land_tax_category_id,
    fields.extra_tax_category_id,
    fields.related_application_type_ids,
    fields.workflow_id,
    fields.rural_percentage_discount,
    fields.archive_document_type_code,
    fields.tenant_id,
    fields.is_auto,
    fields.remaining_period,
    fields.order,
    fields.discounts,
    fields.uats,
    fields.is_internal,
    fields.has_digital_seal,
    fields.is_tax_fixed,
    fields.review_application_type_id,
    fields.internal_application_type_id,
    fields.departments,
    fields.functions,
    fields.is_inbound_category,
    fields.is_internal_category,
    fields.is_outbound_category
]);

import { mapActions, mapGetters } from "vuex";

export default {
    name: "ApplicationTypeForm",
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rules: formSchema.rules(),
            fields,
            model: null,
            optionsLoading: false,
            applyDiscount: false,
            arhiveDocumentTypeList: [],
            isTaxCategoryConditional: false,
            fv: null,
        };
    },
    computed: {
        ...mapGetters({
            record: "applicationType/form/record",
            filters: "applicationType/list/filters",
        }),
        headerText() {
            return this.editMode
                ? this.$t("APPLICATION_TYPE.FORM.EDIT_TITLE")
                : this.$t("APPLICATION_TYPE.FORM.ADD_TITLE");
        },
        filterRelatedAppOptions() {
            if (this.editMode) {
                return fields.related_application_type_ids.options.filter((elem) => elem.value !== this.record.id);
            }

            return fields.related_application_type_ids.options;
        },
        discountsOptions() {
            return fields.discounts.options;
        },
        uatsOptions() {
            return fields.uats.options;
        },
        reviewApplicationTypeId() {
            return fields.review_application_type_id.options.filter((el) => el.is_internal);
        },
        internalApplicationTypeId() {
            return fields.internal_application_type_id.options.filter((el) => el.is_internal);
        },
        departmentOptions() {
            fields.departments.options.unshift({value: "", text: this.$t('GENERAL.SELECT_PLACEHOLDER')});
            return fields.departments.options;
        },
        popoverConfig() {
            return {
                html: true,
                id: 'popover_discounts_options',
                content: () => {
                    let html = '';
                    this.discountsOptions.forEach(element => {
                        html +=`<p>${element.text}</p>`
                    });

                    return html;
                }
            }
        },
    },
    methods: {
        ...mapActions({
            doCreate: "applicationType/form/create",
            doUpdate: "applicationType/form/update",
            doFetch: "applicationType/list/doFetch",
            doGetAllArchiveDocumentsTypes: "archive/document/getAllArchiveDocumentsTypes",
        }),
        hide() {
            this.$emit('cancelForm');
        },
        doReset() {
            this.model = formSchema.initialValues(this.record);
        },
        show() {
            this.$refs['modal'].show();
        },
        async doSubmit() {
            const validate = await this.fv.validate();
            if (validate === "Valid") {
                const { id, ...values } = formSchema.cast(this.model);

                if (this.model.departments === '' || !this.model.departments) {
                    values.functions = [];
                    values.department = "";
                } else {
                    values.department = this.fields.departments.options.find((el) => el.value === this.model.departments)?.text;
                    values.functions = values.functions.filter((el) => el).map((elem) => this.fields.functions.options.find((el) => el.value === elem)?.text);
                }

                delete values.departments;

                const selectedWorkflow = this.fields.workflow_id.options.find(workflow => workflow.value === this.model.workflow_id);
                values.workflow_name = selectedWorkflow?.text;

                values.application_type_scope = 'tenant';

                if (this.model.document_template_id) {
                    values.document_template_name = this.fields.document_template_id.options
                        .find(el => el.value === this.model.document_template_id)?.text;;
                }

                if (this.model.reject_document_template_id) {
                    values.reject_document_template_name = this.fields.reject_document_template_id.options
                        .find(el => el.value === this.model.reject_document_template_id)?.text;
                }

                if (this.model.review_document_template_id) {
                    values.review_document_template_name = this.fields.review_document_template_id.options
                        .find(el => el.value === this.model.review_document_template_id)?.text;
                }

                if (this.model.public_document_template_id) {
                    values.public_document_template_name = this.fields.public_document_template_id.options
                        .find(el => el.value === this.model.public_document_template_id)?.text;
                }

                if (this.model.tenant_id) {
                    values.tenant_name = this.fields.tenant_id.options.find(el => el.value === this.model.tenant_id)?.text;
                }

                if (!this.applyDiscount) {
                    values.rural_percentage_discount = null;
                }

                values.is_tax_category_conditional = this.isTaxCategoryConditional;
                if (this.isTaxCategoryConditional) {
                    values.tax_category_id = null;
                    values.tax_category_conditions = {
                        construction_value: this.model.construction_tax_category_id,
                        land_value: this.model.land_tax_category_id,
                    }
                } else {
                    values.tax_category_conditions = null;
                    values.extra_tax_category_id = null;
                }

                const filteredDiscountsIds = this.model.discounts?.filter(discount => discount.length);
                values.discounts = this.discountsOptions
                    .filter(option => filteredDiscountsIds.some(id => id === option.value))
                    .map(item => ({ id: item.value, name: item.text, description: item.description }));

                const filteredUatsIds = this.model.territorial_administrative_units?.filter(uat => uat.length);
                values.territorial_administrative_units = this.uatsOptions
                    .filter(option => filteredUatsIds.some(id => id === option.value))
                    .map(item => ({ id: item.value, name: item.text, description: item.description }));

                values.related_application_type_ids = values.related_application_type_ids?.filter((el) => el !== '');

                if (this.editMode) {
                    await this.doUpdate({ id, values });
                } else {
                    await this.doCreate(values);
                    await this.doFetch(this.filters);
                }

                this.hide();
            }
        },
        checkReference(){
            const refDoc = this.model.related_application_type_ids.filter((el) => el !== '');
            if (this.model.is_auto && refDoc?.length) {
                Message.warning('APPLICATION_TYPE.MESSAGES.IS_AUTO');
                this.model.is_auto = false;
            }

            if (this.model.is_auto) {
                this.fv.disableValidator(fields.departments.name);
                this.fv.disableValidator(fields.functions.name);
            } else {
                this.fv.enableValidator(fields.departments.name);
                this.fv.enableValidator(fields.functions.name);
            }
        },
        removeValidations() {
            if (this.model.is_internal) {
                this.disableValidatorForInternalApplications();
            } else {
                this.fv.enableValidator(fields.form_citizen_id.name);
                this.fv.enableValidator(fields.reject_form_staff_id.name);
                this.fv.enableValidator(fields.application_document_series_type_id.name);
            }
        },
        disableValidatorForInternalApplications() {
            this.fv.disableValidator(fields.form_citizen_id.name);
            this.fv.disableValidator(fields.reject_form_staff_id.name);
            this.fv.disableValidator(fields.application_document_series_type_id.name);
        },
        async displayFunctions() {
            this.fields.functions.fetchParams = {
                dep_id: this.model[this.fields.departments.name]
            };
            await this.fields.functions.updateOptions()
        }
    },
    async created() {
        this.model = formSchema.initialValues(this.record);

        if (!this.record || !this.record?.related_application_type_ids || this.record.related_application_type_ids?.length === 0) {
            this.model.related_application_type_ids = [""];
        }
        if (!this.record || !this.record.discounts || !this.record.discounts.length) {
            this.model.discounts = [""];
        } else {
            this.model.discounts = this.record.discounts.map(item => item.id);
        }

        if (!this.record || !this.record.territorial_administrative_units || !this.record.territorial_administrative_units.length) {
            this.model.territorial_administrative_units = [""];
        } else {
            this.model.territorial_administrative_units = this.record.territorial_administrative_units.map(item => item.id);
        }
        this.applyDiscount = !!this.record?.rural_percentage_discount;
        this.isTaxCategoryConditional = this.record?.is_tax_category_conditional;
        if (this.isTaxCategoryConditional) {
            this.model.construction_tax_category_id = this.record.tax_category_conditions["construction_value"];
            this.model.land_tax_category_id = this.record.tax_category_conditions["land_value"];
        }

        this.model.has_digital_seal = this.record ? this.record.has_digital_seal : true;

        if (this.record?.department) {
            this.fields.departments.fetchParams = {
                org_id: this.record.tenant_id
            }
            await this.fields.departments.updateOptions();
            this.model.departments = this.fields.departments.options.find((el) => el.text === this.record.department)?.value;

            await this.displayFunctions();
        }

        if (this.record?.functions) {
            this.model.functions = this.record.functions.map((elem) => this.fields.functions.options.find((el) => el.text === elem)?.value);
        } else {
            this.model.functions = [""];
        }
    },
    async mounted() {
        this.fv = createFormValidation("st_modal_application_type_form",this.rules);

        if (!this.applyDiscount) {
            this.fv.disableValidator(fields.rural_percentage_discount.name);
        }

        if (this.isTaxCategoryConditional) {
            this.fv.disableValidator(fields.tax_category_id.name);
        } else {
            this.fv.disableValidator(fields.construction_tax_category_id.name);
            this.fv.disableValidator(fields.land_tax_category_id.name);
        }

        if (this.record?.is_internal) {
            this.disableValidatorForInternalApplications();
        }

        this.optionsLoading = true;

        this.doGetAllArchiveDocumentsTypes()
        .then((data) => {
            this.arhiveDocumentTypeList = data.map(doc => {
                return {
                    value: doc.code,
                    text: doc.name,
                }
            });
        })
        .finally(() => {
            this.optionsLoading = false;
        })
    },
    watch: {
        applyDiscount: {
            immediate: false,
            handler(value) {
                if (this.fv) {
                    if (!value) {
                        this.fv.disableValidator(fields.rural_percentage_discount.name);
                    } else {
                        this.fv.enableValidator(fields.rural_percentage_discount.name);
                    }
                }
            }
        },
        isTaxCategoryConditional: {
            immediate: false,
            handler(value) {
                if (this.fv) {
                    if (!value) {
                        this.fv.disableValidator(fields.construction_tax_category_id.name);
                        this.fv.disableValidator(fields.land_tax_category_id.name);
                        this.fv.enableValidator(fields.tax_category_id.name);
                    } else {
                        this.fv.enableValidator(fields.construction_tax_category_id.name);
                        this.fv.enableValidator(fields.land_tax_category_id.name);
                        this.fv.disableValidator(fields.tax_category_id.name);
                    }
                }
            }
        },
        'model.tenant_id': {
            immediate: true,
            handler(value, oldValue) {
                if (value !== oldValue) {
                    this.model.functions = [""];
                }
            }
        }
    },
};
</script>
<style lang="scss" scoped>
    #popover_discounts_options ::v-deep{
       max-width: 500px !important;
       p {
        margin-bottom: 0.5rem;
       }
    }

    .application-type-category {
        font-size: 1.125rem;
        font-weight: bold;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
</style>
